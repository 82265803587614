<template>
 

  <div v-if="presets_data?.items_preset?.length" class="search_preset">
    <input type="hidden" name="search_preset" value="/explore">

    <div class="content" v-click-outside="hide" @click="toggle">

      <div class="bt">
        {{ $t('diaries_view_presets') }}
        <i v-if="opened" class="icon-caret-up"></i>
        <i v-else class="icon-caret-down"></i>
      </div>
      
      <div :class="{list: true, 'opened': opened}">
        <div 
          v-for="preset in presets_data.items_preset"
          class="item"
          >
          <NuxtLink class="name" :to="preset.url" @click="goPreset(preset.url)">
            {{ preset.name }}
          </NuxtLink>
          <div @click="remove(preset)" class="btn_trash">
            <i class="icon-remove"></i>
          </div>
        </div>
      </div>
    </div>

    <div @click="add()" class="btn" v-if="presetName && presetLink && false">
      {{ $t('universal_button_follow') }}
    </div>
  </div>
  
</template>



<script setup>

const {$api, $ga} = useNuxtApp()
const router = useRouter();
const route = useRoute();

const props = defineProps({
  presetName: {
    type: String
  },
  presetLink: {
    type: String
  }
});

const opened = ref(false);
const start = ref(0)
const limit = ref(20)

const remove = function(item){
  $api.deleteGrowerPresetsExplore(useAuth().getId(), item.id);
  var index = presets_data.value.items_preset.indexOf(item);
  if (index !== -1) {
    presets_data.value.items_preset.splice(index, 1);
  }      
}

/*
const add = async function(){
  if(!props.presetName || !props.presetLink) return;
  var data = await $api.postSearchPreset(
    {
      'name': props.presetName,
      'url': '/explore?' + props.presetLink
    }
  );
  if(data && data.id){
    props.preset_list.push(data);
  }
}
*/

const toggle = function(){
  opened.value = opened.value ? false : true;
}

const show = function(){
  //opened.value = true;
}

const hide = function(){
  opened.value = false;
}

const goPreset = function(url){
  // document.location.href = url;
}


const loadData = async () => {
  
  const response = await $api.getGrowerPresetsExplore(useAuth().getId(), {
    start: start.value,
    limit: limit.value,
  })  
  return response;  
}


const { pending: is_loading,  data: presets_data } = await useLazyAsyncData('presets_data', async () => await loadData())


 

</script>

<style scoped>
.search_preset{
  position: relative;
}
.bt{
  padding: 6px 10px 3px 10px;
  cursor: pointer;
  flex-wrap: nowrap;
  display: flex;
}
.list{
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: var(--un-background-color-gray-light);
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 210px;
}
.list.opened{
  display: block;
}
.list .item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 10px;
}
.list .item:hover{

}
.list .item .btn_trash{
  margin-left: auto;
  cursor: pointer;
  color: var(--gd-text-color-red);
}
</style>
